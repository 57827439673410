import React from "react"
import { Link } from "~components"
import ProcessCard from "./Components/Card"
import Process from "./style"

import { Container, Row, Col } from "react-bootstrap"

const ProcessData = [
  {
    id: "sp1",
    icon: "1",
    title: "Right Tool, Right Job?",
    text: "Software not aligned with workflow needs",
  },
  {
    id: "sp2",
    icon: "2",
    title: "User Experience",
    text: "No mobile version for uploads No Email ticklers",
  },
  {
    id: "sp3",
    icon: "3",
    title: " No MEP Data",
    text: "No intregration of building data",
  },
  {
    id: "sp4",
    icon: "4",
    title: " No Plan Integration",
    text: "No specific job data built in",
  },
  {
    id: "sp5",
    icon: "5",
    title: " No Notificaions",
    text: "No status updates or integrated job scheduling",
  },
  {
    id: "sp6",
    icon: "6",
    title: " No Risk Related Items",
    text: "No high risk accounting or record keeping",
  },
  {
    id: "sp7 ",
    icon: "7",
    title: " No Mechanical Unit Start Up Reporting",
    text: "Creates bottleneck for client (Team) turn around time",
  },
  {
    id: "sp8",
    icon: "8",
    title: " Requires High Level Oversight",
    text: "General software disorganization creates need for overqualified management to engage in operations",
  },
]
export default function ProcessSection({ ...rest }) {
  return (
    <Process {...rest}>
      <Container>
        <Row className="align-items-center justify-content-center justify-content-xl-start">
          <Col xs="12" className="col-xl-7 col-lg-8 text-center text-xl-start">
            {/* <Process.Subtitle mbLG="10px" as="h5" fontColor="#5034fc">Our Process</Process.Subtitle> */}
            <Process.Title as="h2" mb="40px" mbLG="80px">
              The Problems:
              <br className="d-none d-xxl-block" /> Current State Software
            </Process.Title>
          </Col>
          <Col xs="12" className="col-xl-11">
            <Process.Box as="div" className="widgets">
              <Row className="justify-content-center justify-content-xl-between">
                {ProcessData.map(
                  ({ title, text, icon, iconBackground, id }, index) => {
                    return (
                      <Col
                        xs="12"
                        className="col-lg-3 col-md-4 col-xs-6 mb-6 "
                        key={index}
                      >
                        <ProcessCard icon={icon} title={title} text={text} />
                      </Col>
                    )
                  }
                )}
              </Row>
            </Process.Box>
          </Col>
        </Row>
      </Container>
    </Process>
  )
}