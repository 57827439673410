import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Images } from "~data"
import Cta from "./style"
import { Link } from "~components"

export default function CtaSection() {
  return (
    <Cta>
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" className="col-xl-7 text-center">
            <Cta.Title as="h2" fontColor="#fff" mb="10px">
              You're invited to join us!
            </Cta.Title>
            <Cta.Text fontColor="#fff" as="h3">
              Kyle@Warranty-Worx.com
              <br className="d-none d-xs-block" /> Kyle Avery, CEO
            </Cta.Text>
            <Cta.Button
              sizeX="180px"
              sizeY="56px"
              mt="20px"
              as={Link}
              to="https://www.warranty-worx.com/login"
            >
              Early Access
            </Cta.Button>
          </Col>
        </Row>
      </Container>
    </Cta>
  )
}

// style={{backgroundImage: `url(${})`}}