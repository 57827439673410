import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Service from "./style"
import SectionTitle from "./Components/SectionTitle"
import ServicesCard from "./Components/Card"

const ServiceData = [
  {
    id: "ss1",
    icon: "fas fa-layer-group",
    title: "Workflow Specific Software",
    text: "Built for your needs",
    iconBackground: "#6001d3",
  },
  {
    id: "ss2",
    icon: "fas fa-bolt",
    title: "Optimized User Experience",
    text: "Quick and intuitive workflow for lean practices",
    iconBackground: "#fd346e",
  },
  {
    id: "ss3",
    icon: "fas fa-mobile",
    title: "Mobile Integration",
    text: "Complete work onsite",
    iconBackground: "#1de2cf",
  },
  {
    id: "ss4",
    icon: "fas fa-chart-pie",
    title: "GC Dashboard",
    text: "Build team, upload drawings and MEP schedules quickly",
    iconBackground: "#ffd700",
  },
  {
    id: "ss5",
    icon: "fas fa-bell",
    title: "Integrated email notifications",
    text: "Keeping team members on task",
    iconBackground: "#6001d3",
  },
  {
    id: "ss6",
    icon: "fas fa-chart-pie",
    title: "Risk Analysis Tracking",
    text: "High risk items will be saved for 10 years",
    iconBackground: "#fd346e",
  },
  {
    id: "ss7",
    icon: "fas fa-scroll",
    title: "Enhanced Reports",
    text: "Job, user, and organization specific reports",
    iconBackground: "#1de2cf",
  },
  {
    id: "ss8",
    icon: "fas fa-chart-pie",
    title: "Multiple Job Management",
    text: "Streamlined job task managment",
    iconBackground: "#ffd700",
  },
]
export default function ServicesSectionOne() {
  return (
    <Service>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-8 text-center">
            <SectionTitle
              subTitle="The Fixes"
              title="Provided Features"
              titleProps={{ mb: "40px", mbLG: "75px" }}
              subTitleProps={{ mb: "10px", }}
            />
          </Col>
        </Row>
        <Row
          className="justify-content-center aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration={500}
          data-aos-delay={500}
          data-aos-once="true"
        >
          {/* Single Services */}
          {ServiceData.map(({ title, icon, text, id, iconBackground }) => {
            return (
              <Col
                xs="h2"
                className="col-xl-3 col-lg-4 col-sm-6 col-xs-8"
                key={id}
              >
                <ServicesCard
                  title={title}
                  text={text}
                  icon={icon}
                  iconBackground={iconBackground}
                />
              </Col>
            )
          })}
          {/*/ .Single Services */}
        </Row>
      </Container>
    </Service>
  )
}