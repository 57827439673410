import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import Hero from "./style"
import Video from "~components/VideoModal"
import { Link } from "~components"

export default function HeroSection({ ...rest }) {
  return (
    <Hero backgroundColor="#e6f8f9" {...rest}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-xs-11 order-2 order-lg-1">
            <Hero.Content>
              <Hero.Subtitle as="h4" fontColor="#15cda8">Warranty Software Solutions</Hero.Subtitle>
              <Hero.Title as="h2">Warranty Worx</Hero.Title>
              {/* <Hero.Text>When, while lovely valley teems with vapour around<br className="d-none d-xs-block" /> meand meridian
            sun strikes the upper impenetroble<br className="d-none d-xs-block" /> foliage of my trees, and but a thousand.</Hero.Text> */}
              <Hero.Buttons mt="30px" mtLG="50px">
                <Hero.Button
                  className="btn-1 btn-electric-violet-2"
                  to="https://www.warranty-worx.com/login"
                  as={Link}
                >
                  Early Access
                </Hero.Button>
                {/* <Hero.Button className="btn-outline-shark" to="/" as={Link}>Get a Quote</Hero.Button> */}
              </Hero.Buttons>
            </Hero.Content>
          </Col>
          <Col
            xs="12"
            className="col-xxl-7 col-xl-6 col-lg-5 col-md-9 col-xs-11 order-1 order-lg-2"
          >
            <Img
              src="../../../assets/image/home-startup/hero-img.png"
              alt="Hero"
              layout="fullWidth"
              placeholder="blurred"
            />
            {/* Video Button */}
          </Col>
        </Row>
      </Container>
    </Hero>
  )
}