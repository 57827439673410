import React from "react"
import { PageWrapper } from "~components/Core"
import HeroSection from "~sections/startup/Hero"
import ServicesSectionOne from "~sections/startup/ServicesOne"
import ServicesSectionTwo from "~sections/startup/ServicesTwo"
import AboutSection from "~sections/startup/About"
import ProcessSection from "~sections/startup/Process"

import HeaderButton from "~sections/startup/Header"

import CounterSection from "~sections/marketing/Counter/CounterSection"
import CtaSection from "~sections/it/Cta"

import FooterFive from "~sections/agency/FooterFive"

const header = {
  headerClasses: "",

  containerFluid: false,
  buttonBlock: (
    <HeaderButton
      className="d-none d-xs-inline-flex"
      btnText="Purchase"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function Startup() {
  return (
    <PageWrapper>
      <HeroSection />
      <ServicesSectionTwo />
      <AboutSection />
      <ProcessSection />
      <ServicesSectionOne />
      {/* <IntegrationSection />*/}
      <CounterSection />

      <CtaSection />
      <FooterFive />
    </PageWrapper>
  )
}
