import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import CountUp from "react-countup"
import Counter from "./style"
import Process from "~sections/it/Process/style"
import LazyLoad from "react-lazyload"
import { SuperTag } from "~components"

export default function CounterSection() {
  return (
    <Counter>
      <Container>
        <Process.Box pb="30p" pbXL="50px">
          <Row>
            <Col xs="12" className="text-center">
              <Process.Subtitle as="h5">And it works!</Process.Subtitle>
              <Process.Title as="h2">
              Save time and money with the highest level of user support available
              </Process.Title>
            </Col>
          </Row>
        </Process.Box>
        <Row className="row justify-content-center text-center">
          <Col xs="6" className="col-6 col-lg-3 col-md-4 col-xs-6">
            <LazyLoad>
              <Counter.Single>
                <Counter.Title as="h3" fontColor="#2b59ff">
                  <CountUp
                    className="counter"
                    start={0}
                    end={24}
                    duration={2.75}
                  />
                 hrs
                </Counter.Title>
                <Counter.Text>
                  <SuperTag value="Saved multiple hours of work per team  member per month " />
                </Counter.Text>
              </Counter.Single>
            </LazyLoad>
          </Col>
          {/* <Col xs="6" className="col-6 col-lg-3 col-md-4 col-xs-6">
            <LazyLoad>
              <Counter.Single>
                <Counter.Title as="h3" fontColor="#ff5722">
                  $
                  <CountUp
                    className="counter"
                    start={0}
                    end={4}
                    duration={2.75}
                  />
                  M
                </Counter.Title>
                <Counter.Text>
                  <SuperTag value="Up to 33% savings per job" />
                </Counter.Text>
              </Counter.Single>
            </LazyLoad>
          </Col> */}
          <Col xs="6" className="col-6 col-lg-3 col-md-4 col-xs-6">
            <LazyLoad>
              <Counter.Single>
                <Counter.Title as="h3" fontColor="#22cc74">
                  
                  <CountUp
                    className="counter"
                    start={0}
                    end={10}
                    duration={2.75}
                  />
                 
                </Counter.Title>
                <Counter.Text>
                  <SuperTag value="Store your data for up to 10 years for compliance" />
                </Counter.Text>
              </Counter.Single>
            </LazyLoad>
          </Col>
          {/* <Col xs="6" className="col-6 col-lg-3 col-md-4 col-xs-6">
            <LazyLoad>
              <Counter.Single>
                <Counter.Title as="h3" fontColor="#ff971d">
                  <span className="counter">93</span>%
                </Counter.Title>
                <Counter.Text>
                  <SuperTag value="Success rate on <br className='d-none d-xs-block' /> last 05 years." />
                </Counter.Text>
              </Counter.Single>
            </LazyLoad>
          </Col> */}
        </Row>
      </Container>
    </Counter>
  )
}